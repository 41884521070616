html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

.main {
  font: 'Poppins', Sans;
  font-weight: 900;
  color: white;
  background-image: linear-gradient(45deg, #5EC1F3, #DE33E7);
  min-height: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column;
}

.content {
  height: 100%;
  font-size: 65px;
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: space-around;
}

.title {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding-top: 100px;
}

.title > img {
  justify-self: center;
  height: 200px;
}

.title > div {
  display: flex;
  justify-content: center;
  font-size: 25px;
  margin-top: 10px;
}

.row {
  margin-top: 80px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  text-align: center;
}

.clicked-content {
  min-height: 100vh;
  max-width: 800px;
  margin-top: 40px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  font-size: 24px;
  width: 100%;
}

.clicked-content > div {
  width: 100%;
}

.content-title {
  font-size: 45px;
  margin-bottom: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.content-title > .mail-button {
  display: flex;
  align-self: center;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.icon-link {
  cursor: pointer;
  width: 300px;
  display: flex;
  flex-flow: column;
  align-items: center;
  font-size: 30px;
  user-select: none;
  -webkit-user-select: none;
}

.icon-link:hover {
  text-shadow: 0px 0px 25px rgba(255, 255, 255, 0.7);
}

.selected {
  text-shadow: 0px 0px 25px rgba(255, 255, 255, 0.7);
}

.icon-link > .material-symbols-outlined {
  color: white;
  font-size: 100px;
}

.item {
  position: relative;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.45);
}

.item:hover > .caption {
  transition: opacity 600ms;
  opacity: 1;
}

img {
  height: 400px;
}

.item {
  display: flex;
  flex-flow: column;
  margin-bottom: 25px;
  overflow: hidden;
  height: 400px;
  width: auto;
  margin-right: 40px
  /* align-items: center; */
  /* justify-content: center; */
}


.caption {
  opacity: 0;
  transition: opacity 2000ms;
  font-weight: 900;
  color: white;
  font-size: 16px;
  padding: 10px;
  text-shadow: 2px 2px 3px gray;
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow-wrap: break-word;
  box-sizing: border-box;
  background-color: rgba(0,0,0,0.45);
}

.content-columns {
  column-count: 3;
}

.example {
  height: 250px;
  box-shadow: 2px 2px 4px rgba(0,0,0,0.45);
  cursor: pointer;
  border: 4px solid white;
}


@media (max-width: 800px) {
  .main {
    padding: 30px;
    flex-flow: column;
  }


  .clicked-content {
    font-size: 18px;
  }

  .title {
    display: flex;
    justify-content: center;
    padding-top: 30px;
  }
  
  .title > img {
    justify-self: center;
    height: 120px;
  }
  
  .content {
    font-size: 40px;
  }

  .icon-link {
    font-size: 24px;
    width: unset;
  }
  .content-title {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .content-title > .mail-button {
    font-size: 20px;
    padding: 8px;
  }


  .icon-link > .material-symbols-outlined {
    color: white;
    font-size: 50px;
    margin-top: 20px;
  }

  .item {
    display: flex;
    flex-flow: column;
    margin-bottom: 25px;
    overflow: hidden;
    height: auto;
    width: 100%;
    margin-right: 40px
  }

  .content-columns {
    column-count: 1;
  }
  

.row {
  margin-top: 50px;
}

}
